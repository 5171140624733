<template>
  <div>
    <!-- 中間區塊 上課紀錄 讀書規劃 聯絡紀錄  -->
    <div class="member_lesson_infor">
      <ul class="tabs">
        <li
          class="tab_li"
          v-for="(t, k) in tabList"
          :key="k"
          @click="checkTab(t.id)"
          :class="{ active: stuId == t.id }"
        >
          {{ t.title }}
        </li>
      </ul>
      <div class="manager_lesson_infor_container">
        <!-- 課程規劃 -->
        <div v-if="stuId == 2" id="infor2">
          <div class="manager_lesson_infor_title">
            課程規劃：{{ studentInfo.name }}同學
            <!-- <span style="font-size: 14px"
              >課程規劃結束后，以學生最近一次測驗成績為該次測驗成績。</span
            > -->
          </div>
          <div class="manager_record_search_container">
            <div class="manager_record_search">
              <el-date-picker
                type="month"
                v-model="searchLearnData.logMonth"
                @change="changeMonth($event)"
                placeholder="選擇月份"
              />
            </div>
          </div>
          <el-table :data="learnPlanList.list">
            <el-table-column label="規劃日期" prop="scheduleDate">
            </el-table-column>
            <el-table-column prop="name" label="一對一輔導">
              <el-table-column label="上課時間" prop="classTime">
                <template slot-scope="scope">
                  {{ scope.row.haveClass ? scope.row.classTime : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="上課狀態" prop="classStatus">
                <template slot-scope="scope">
                  {{
                    scope.row.haveClass
                      ? rendeStatusString(scope.row.classStatus)
                      : "--"
                  }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="name" label="課程測驗">
              <el-table-column label="課前" prop="beforeTestScore">
                <template slot-scope="scope">
                  <span v-if="scope.row.haveBeforeTest">{{
                    scope.row.isClosed ? scope.row.beforeTestScore : "未結束"
                  }}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="課後" prop="afterTestScore">
                <template slot-scope="scope">
                  <span v-if="scope.row.haveAfterTest">{{
                    scope.row.isClosed ? scope.row.afterTestScore : "未結束"
                  }}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="課後作業成績" prop="academicRecord">
              <template slot-scope="scope">
                <span v-if="scope.row.haveHomework">
                  <span v-if="scope.row.isClosed">
                    {{ scope.row.academicRecord }}</span
                  >
                  <span v-else>
                    <el-button
                      type="primary"
                      size="small"
                      @click="openStuDialog(6, scope.row)"
                      >填寫作業成績</el-button
                    ></span
                  >
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="規劃狀態">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  :disabled="scope.row.isClosed"
                  @click="closeLearnPlan(scope.row.id)"
                  >結束</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div v-if="!studentDetailData" class="noDataTab">暫無數據</div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchLearnData.pageNum"
              :page-sizes="configPage"
              :page-size="searchLearnData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="learnPlanList.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 上課紀錄 -->
        <div v-if="stuId == 1" id="infor1">
          <div class="manager_lesson_infor_title">
            上課記錄：{{ studentInfo.name }}同學
          </div>
          <div class="manager_record_search_container">
            <div class="manager_record_search">
              <el-date-picker
                type="month"
                v-model="searchLeasonData.logMonth"
                @change="changeMonth($event)"
                placeholder="選擇月份"
              />
            </div>
          </div>
          <div
            class="manager_list_container"
            v-for="(leasson, i) in studentDetailData"
            :key="i"
          >
            <div class="manager_list_title">
              <div>上課日期</div>
              <div>上課時間</div>
              <div>科目老師</div>
              <div>上課狀態</div>
            </div>
            <div class="manager_list_infor">
              <div>{{ leasson.classDate }}</div>
              <div>{{ leasson.classTime }}</div>
              <div>{{ leasson.curriculum }}-{{ leasson.teacherName }}</div>
              <div>{{ classStatusL[leasson.classStatus] }}</div>
            </div>

            <!-- <div class="grey" v-else-if="leasson.classStatus=='5'">上課已取消</div> -->
            <div class="dis_flex">
              <!-- 
              <div
                v-if="leasson.classStatus == '3'"
                class="student_class_record_btn"
                @click="openStuDialog(2, leasson)"
              >
                 <div>
                  <span style="position:relative;">
                    填寫上課紀錄
                    <span v-if="!leasson.managerReturnInClass" class="notice_point"></span>
                  </span>
                </div>
              </div>
              -->
              <!-- v-if="leasson.sendAcademic"-->
              <!-- <div
                class="student_class_record_btn"
                @click="openStuDialog(6, leasson)"
              >
                <div>填寫作業成績</div>
              </div> -->
              <div
                class="student_class_record_btn"
                v-if="leasson.classStatus == '1'"
              >
                <div
                  v-if="leasson.canCanceled"
                  @click="openStuDialog(1, leasson)"
                >
                  取消上課
                </div>
                <!-- <div v-else @click="openStuDialog(1,leasson.orderId)">填寫取消上課房間連接</div> -->
              </div>
              <!-- <div class="grey" v-if="leasson.classStatus == '4'">取消上課</div> -->
              <div
                v-if="!leasson.deleteTag"
                class="student_class_record_btn"
                @click="openStuDialog(7, leasson)"
              >
                <div>
                  <span style="position: relative">
                    填寫上課錄影
                    <!-- <span v-if="!leasson.managerReturnInClass" class="notice_point"></span> -->
                  </span>
                </div>
              </div>
              <div
                v-if="!leasson.orderStatus"
                class="student_class_record_btn"
                @click="openStuDialog(8, leasson)"
              >
                <div>
                  <span style="position: relative">審核上課狀況</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!studentDetailData" class="noDataTab">暫無數據</div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchLeasonData.pageNum"
              :page-sizes="configPage"
              :page-size="searchLeasonData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="searchLeasonData.counts"
            ></el-pagination>
          </div>
        </div>
        <!--上課記錄end-->
        <!-- 讀書規劃 -->
        <!--
        <div v-if="stuId == 1" id="infor2">
          <div class="manager_lesson_infor_title">
            讀書規劃：{{ studentInfo.name }}同學
          </div>
          <div class="manager_record_search_container">
            <div class="manager_record_search">
              <el-date-picker
                type="month"
                v-model="searchReadData.logMonth"
                @change="changeMonth($event)"
                placeholder="選擇月份"
              />
            </div>
          </div>
          <div class="study_list_title">
            <div class="study_list_title_day">日期</div>
            <div class="study_list_title_text">讀書內容</div>
            <div class="study_list_title_edit">動作</div>
          </div>
          <div v-if="stuReadList && stuReadList.length">
            <div
              class="study_list_infor"
              v-for="(red, k) in stuReadList"
              :key="k"
            >
              <div class="study_list_infor_day">{{ red.readingDate }}</div>
              <div class="study_list_infor_text">
                {{ red.readingPlanContent }}
              </div>
              <div class="study_list_infor_edit">
                <div
                  class="study_list_infor_btn"
                  @click="openStuDialog(3, red)"
                >
                  編輯
                </div>
              </div>
            </div>
          </div>
          <div v-else class="noDataTab">暫無數據</div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchReadData.pageNum"
              :page-sizes="configPage"
              :page-size="searchReadData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="searchReadData.counts"
            ></el-pagination>
          </div>
        </div>
        -->
        <!-- 讀書規劃end -->
        <!-- 聯絡紀錄 -->
        <div v-if="stuId == 0" id="infor3">
          <div class="manager_lesson_infor_title">
            聯絡紀錄：{{ studentInfo.name }}同學
          </div>
          <div class="manager_record_search_container">
            <div class="manager_record_search">
              <el-date-picker
                type="month"
                v-model="searchContactData.logMonth"
                @change="changeMonth($event)"
                placeholder="選擇月份"
              />
            </div>
          </div>
          <div class="manager_list_title">
            <div>聯絡日期</div>
            <div>聯絡人</div>
            <div>下次聯絡</div>
            <div>聯絡內容</div>
          </div>
          <div v-if="stuContactList && stuContactList.length">
            <div
              class="manager_list_infor border_left_right_none"
              v-for="(contact, t) in stuContactList"
              :key="t"
            >
              <div>{{ contact.contactDate }}</div>
              <div>{{ contact.interrelation }}</div>
              <div>{{ contact.nextContactDate }}</div>
              <div>
                <div
                  class="manager_list_infor_btn contact_check_btn"
                  @click="openStuDialog(4, contact)"
                >
                  查看
                </div>
              </div>
            </div>
          </div>
          <div v-else class="noDataTab">暫無數據</div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchContactData.pageNum"
              :page-sizes="configPage"
              :page-size="searchContactData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="searchContactData.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 聯絡紀錄end -->
        <!-- 成績紀錄 -->
        <div v-if="stuId == 3">
          <div class="manager_lesson_infor_title">
            成績紀錄：{{ studentInfo.name }}同學
          </div>
          <div class="manager_list_container">
            <el-table
              @sort-change="sortDateScore"
              :data="testScoreList"
              empty-text="暫無數據"
              style="width: 100%"
            >
              <el-table-column
                prop="scheduleDate"
                label="考試日期"
                sortable="custom"
              ></el-table-column>
              <el-table-column
                prop="curriculum"
                label="考試科目"
              ></el-table-column>
              <el-table-column prop="" label="考試類別" sortable="testType">
                <template slot-scope="scope">
                  {{ testTypeList[scope.row.testType] }}
                </template>
              </el-table-column>
              <el-table-column
                prop="academicRecord"
                label="考試成勣"
                sortable="custom"
              ></el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button type="primary" @click="openStuDialog(9, scope.row)"
                    >編輯</el-button
                  ><el-button @click="delTestScore(scope.row.id)"
                    >刪除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchTestScoreData.pageNum"
              :page-sizes="configPage"
              :page-size="searchTestScoreData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="searchTestScoreData.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 成績紀錄end -->
      </div>
    </div>
    <!-- 中間區塊 學生列表 老師列表end -->

    <!-- ... pup視窗-取消上課提示 ... -->
    <div class="pup_cancel_class" v-if="dialogId == 1">
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 90px">
          <div>
            <div class="pup_cancel_tip_title">取消上課</div>
            <div class="pup_cancel_tip_infor">
              {{ classItemDetail.classDate }}&nbsp;{{
                classItemDetail.classTime
              }}&nbsp;
              <br />
              {{ studentInfo.name }}{{ classItemDetail.teacherName }}老師的{{
                classItemDetail.curriculum
              }}
            </div>
            <div class="pup_cancel_tip_reason">
              <textarea
                v-model="cancelReason"
                placeholder="填寫取消上課原因..."
              ></textarea>
            </div>
            <!-- <div class="pup_manager_input_textarea">
              <textarea></textarea>
            </div>-->
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="getCancelClass">
            確認取消
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-取消上課提示end ... -->

    <!-- ... pup視窗-上課回報紀錄 ... -->
    <div
      class="pup_class_report_s"
      v-if="dialogId == 2 || dialogId == 7 || dialogId == 8"
    >
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_study_infor_title" style="margin-bottom: 20px">
              {{ classItemDetail.classDate }}&nbsp;{{
                classItemDetail.classTime
              }}&nbsp;{{ classItemDetail.curriculum }}
              &nbsp;&nbsp;
              <span v-if="classItemDetail.canEditReturn"
                >({{ classItemDetail.remarks }})</span
              >
            </div>
            <div v-if="dialogId == 2">
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">
                  上課回報
                  <span>*必須</span>
                </div>
                <div class="pup_manager_input_textarea">
                  <textarea
                    v-model="editClassForm.managerReturnInClass"
                  ></textarea>
                </div>
              </div>
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">發送作業</div>
                <div class="pup_manager_input">
                  <select v-model="editClassForm.sendAcademic">
                    <option :value="true">是</option>
                    <option :value="false">否</option>
                  </select>
                </div>
              </div>
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">上課星數</div>
                <div class="pup_manager_input">
                  <select v-model="editClassForm.effect">
                    <!-- <option value>選擇評分星數</option> -->
                    <option v-for="g in 5" :key="g" :value="g">
                      {{ g }}顆星
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="dialogId == 7">
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">
                  上課錄影
                  <span>*必須</span>
                </div>
                <div class="pup_manager_input_textarea">
                  <textarea v-model="editClassForm.playbackUrl"></textarea>
                </div>
              </div>
            </div>
            <div v-if="dialogId == 8">
              <div class="pup_study_infor_title" style="margin: 20px">
                提示：上課狀況只能編輯一次
              </div>

              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">上課狀況</div>
                <div class="pup_manager_input">
                  <select v-model="editClassForm.orderStatus">
                    <option :value="0">選擇狀況</option>
                    <option :value="1">完成上課</option>
                    <option :value="4">網路故障</option>
                    <option :value="5">老師曠課</option>
                    <option :value="6">學生曠課</option>
                    <option :value="7">學生請假</option>
                    <option :value="8">老師請假</option>
                    <option :value="9">學生臨時狀況</option>
                    <option :value="10">老師臨時狀況</option>
                    <option :value="11">取消上課</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="saveClassBack">儲存</div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-上課回報紀錄end ... -->
    <div v-if="dialogId == 6">
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">作業成績</div>
              <div class="pup_manager_input">
                <el-input
                  v-model="academicForm.academicRecord"
                  label="描述文字"
                  @change="setRecordCount"
                ></el-input>
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="saveClassBack">
            發送成績
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-編輯讀書內容 ... -->
    <div class="pup_study_infor" v-if="dialogId == 3">
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div class="pup_study_infor_title">
              {{ readForm.readingDate }}&nbsp;{{ studentInfo.name }}讀書內容
            </div>
            <div class="pup_study_infor_textarea">
              <textarea v-model="readForm.readingPlanContent"></textarea>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="saveStuRead">
            儲存內容
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-編輯讀書內容end ... -->

    <!-- ... pup視窗-聯絡內容 ... -->
    <div class="pup_contact_infor" v-if="dialogId == 4">
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div class="pup_contact_infor_title">
              {{ contactForm.contactDate }}&nbsp;{{ studentInfo.name }}聯絡內容
            </div>
            <div class="pup_contact_infor_textarea">
              <textarea v-model="contactForm.contactContent"></textarea>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div
            class="pup_manager_window_btn_no"
            v-if="!contactForm.contactContent"
          >
            儲存內容
          </div>
          <div class="pup_manager_window_btn" v-else @click="saveContact">
            儲存內容
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-聯絡內容end ... -->
    <!-- ... pup視窗-填写房间号 ... -->
    <div class="pup_contact_infor" v-if="dialogId == 5">
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div class="pup_study_infor_title">
              {{ readForm.readingDate }}&nbsp;{{ studentInfo.name }}房間連接
            </div>
            <div class="pup_study_infor_textarea">
              <textarea v-model="readForm.readingPlanContent"></textarea>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="saveStuRead">
            儲存內容
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-填写房间号 ... -->

    <!-- ... pup視窗-学校成績 ... -->
    <div class="pup_contact_report" v-if="dialogId == 9">
      <div class="pup_manager_bg" @click="openStuDialog(0)"></div>
      <div class="pup_manager_window">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試日期</div>
              <div class="pup_manager_input">
                <el-date-picker
                  v-model="testScoreForm.scheduleDate"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試科目</div>
              <div class="pup_manager_input">
                <select v-model="testScoreForm.curriculum">
                  <option v-for="(c, k) in curriculumList" :key="k" :value="c">
                    {{ c }}
                  </option>
                </select>
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試類別</div>
              <div class="pup_manager_input">
                <select v-model="testScoreForm.testType">
                  <option value="Section">段考</option>
                  <option value="Weekly">周考</option>
                </select>
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">考試分數</div>
              <div class="pup_manager_input">
                <input v-model="testScoreForm.academicRecord" />
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openStuDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="editScore">發送成績</div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-学校成績end ... -->
  </div>
</template>
<script>
import { Message } from "element-ui";
</script>
<script>
import { mapMutations } from "vuex";
import {
  getReadList,
  getEditRead,
  getContactList,
  getEditContactList,
  getEidtContactList,
  getCancelClassOrder,
  getClassOrderList,
  getCancleClassById,
  getEditClassLog,
  getStudentAcademic,
  getEditAcademic,
  getEditClassStatus,
  getEditPlayUrl,
  getAddHomeworkAcademic,
  getStuLearnPlanList,
  getClosedLearnPlan,
  getStuTestScoreList,
  getEditStuTestScore,
  getDelStuTestScore,
  getHsdCulum,
} from "../../api/api";
import { Message } from "element-ui";
export default {
  data() {
    return {
      someInt: "",
      dialogId: 0,
      testTypeList: { Section: "段考", Weekly: "周考" },
      configPage: [5, 10, 15, 20],
      fileList: [], //上传课程录影文件
      contactForm: {},
      editClassForm: {},
      academicForm: { academicRecord: null },
      testScoreForm: {},
      searchReadData: { pageNum: 1, pageSize: 10, logMonth: "" },
      searchContactData: { pageNum: 1, pageSize: 10, logMonth: "" },
      searchLeasonData: { pageNum: 1, pageSize: 5, logMonth: "" }, //上课记录
      searchLearnData: { pageNum: 1, pageSize: 10, logMonth: "" },
      searchTestScoreData: { pageNum: 1, pageSize: 10 },
      searchAcademicData: {
        studentId: "",
        pageNum: 1,
        pageSize: 10,
        sort: "ScheduleDate",
      },
      logMonth: "", //时间月历
      readForm: {},
      studentInfo: {
        name: "",
        id: "",
      },
      cancelReason: "", //取消上课原因
      stuId: 2,
      tabList: [
        { id: 3, title: "成績記錄" },
        { id: 0, title: "聯絡記錄" },
        { id: 1, title: "上課紀錄" },
        { id: 2, title: "課程規劃" },
      ], //"讀書規劃", "作業成績"
      sortOpts: "",
      studentDetailData: [], //學生上課記錄
      stuReadList: [],
      stuContactList: [],
      academicRecordList: [], //作業成績
      testScoreList: [], //考試成勣
      learnPlanList: { count: 0, list: [] }, //課程規劃
      classStatusL: {
        1: "準備上課",
        2: "签到异常",
        3: "完成上課",
        4: "正在上課",
        5: "上課取消",
      },
      classItemDetail: {},
      curriculumList: [],
    };
  },
  created() {
    let name = decodeURI(this.$route.query.name);
    this.studentInfo = { id: this.$route.query.id, name: name };
    this.searchAcademicData.studentId = this.studentInfo.id;
    this.getClassOrderList();
    // this.getReadList();
    this.getContactList();
    this.getAcademicList();
    this.getLearnPlanList();
    this.getTestScoreList();
    this.getCulumList();
  },
  methods: {
    //获取科目
    async getCulumList() {
      const res = await getHsdCulum();
      if (res && res.success == 1) {
        this.curriculumList = res.data;
      }
    },
    //编辑成绩
    async editScore() {
      const res = await getEditStuTestScore({
        ...this.testScoreForm,
        userId: this.studentInfo.userId,
      });
      if (res && res.code == 200 && res.success == 1) {
        Message({ type: "success", message: "上傳成功" });
        this.studentClassStatus = 0;
      }
    },
    //刪除成績
    delTestScore(id) {
      this.$confirm("是否確認刪除這條成績?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getDelTestScore(id);
        })
        .catch(() => {});
    },
    async getDelTestScore(id) {
      const res = await getDelStuTestScore({ id });
      if (res && res.success == 1) {
        Message({ type: "success", message: "刪除成功" });
        this.getTestScoreList();
      }
    },
    //列表切换
    checkTab(i) {
      this.stuId = i;
    },
    changeMonth(d) {
      let m = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      let mon = `${d.getFullYear()}-${m}`;
      this.logMonth = mon;
      if (this.stuId == 2) {
        this.getLearnPlanList();
      }
      if (this.stuId == 1) {
        this.getClassOrderList();
      }
      // if (this.stuId == 1) {
      //   // this.getReadList();
      //   this.getAcademicList();
      // }
      if (this.stuId == 0) {
        this.getContactList();
      }
    },
    sortDateScore(sort) {
      let order = "";
      if (sort.prop == "classDate") {
        order = "ScheduleDate";
      } else if (sort.prop == "curriculum") {
        order = "Curriculum";
      } else {
        order = "AcademicRecord";
      }
      if (sort.order == "descending") {
        order = `-${order}`;
      }
      if (this.stuId == 3) {
        this.searchTestScoreData.sort = order;
        this.getTestScoreList();
      } else {
        this.searchAcademicData.sort = order;
        this.getAcademicList();
      }
    },
    //課程規劃結束
    async closeLearnPlan(learnPlanId) {
      const res = await getClosedLearnPlan({ learnPlanId });
      if (res && res.success == 1) {
        Message.success("結束成功");
        this.getLearnPlanList();
      }
    },
    //學習規劃列表
    async getLearnPlanList() {
      const res = await getStuLearnPlanList({
        ...this.searchLearnData,
        logMonth: this.logMonth,
        userId: this.studentInfo.id,
      });
      if (res && res.success == 1) {
        let data = res.data.data ? res.data.data : [];
        this.$set(this.learnPlanList, "counts", res.data.count);
        this.$set(this.learnPlanList, "list", data);
      }
    },
    //段考成绩list
    async getTestScoreList() {
      const res = await getStuTestScoreList({
        ...this.searchTestScoreData,
        userId: this.studentInfo.id,
      });
      if (res && res.success == 1) {
        this.testScoreList = res.data.data;
        this.searchTestScoreData.counts = res.data.count;
      }
    },
    //作業成績列表
    async getAcademicList() {
      const res = await getStudentAcademic({
        ...this.searchAcademicData,
        logMonth: this.logMonth,
      });
      if (res && res.success == 1) {
        this.academicRecordList = res.data.data;
        this.searchAcademicData.counts = res.data.count;
      }
    },
    //上课记录
    async getClassOrderList() {
      const res = await getClassOrderList({
        ...this.searchLeasonData,
        logMonth: this.logMonth,
        userId: this.studentInfo.id,
      });
      if (res && res.success == 1) {
        this.studentDetailData = res.data.data;
        this.searchLeasonData.counts = res.data.count;
      }
    },
    //编辑上课记录回报
    async saveClassBack() {
      let result,
        id = this.classItemDetail.classOrderId,
        learnPlanId = this.classItemDetail.id;
      if (this.dialogId == 2) {
        result = await getEditClassLog({
          ...this.editClassForm,
          orderId: id,
          // sendAcademic: false,
        });
      }
      if (this.dialogId == 7) {
        result = await getEditPlayUrl({ ...this.editClassForm, orderId: id });
      }
      if (this.dialogId == 6) {
        result = await getAddHomeworkAcademic({
          ...this.academicForm,
          learnPlanId,
        });
      }
      if (this.dialogId == 8) {
        result = await getEditClassStatus({
          ...this.editClassForm,
          orderId: id,
        });
      }
      if (result && result.success == 1) {
        this.$message.success("success");
        !this.dialogId == 6 && this.getClassOrderList();
        this.dialogId = 0;
      }
    },
    //作业成绩转int
    setRecordCount(i) {
      this.academicForm.academicRecord = parseInt(i);
    },
    //读书列表
    async getReadList() {
      const res = await getReadList({
        ...this.searchReadData,
        // logMonth: this.logMonth,
        userId: this.studentInfo.id,
      });
      if (res.code == 200) {
        this.stuReadList = res.data.data;
        this.searchReadData.counts = res.data.count;
      }
    },
    //编辑读书内容
    async saveStuRead() {
      const res = await getEditRead(this.readForm);
      if (res && res.code == 200) {
        this.dialogId = 0;
        this.getReadList();
      }
    },
    //联络列表
    async getContactList() {
      const res = await getContactList({
        ...this.searchContactData,
        logMonth: this.logMonth,
        userId: this.studentInfo.id,
      });
      if (res.code == 200) {
        this.stuContactList = res.data.data;
        this.searchContactData.counts = res.data.count;
      }
    },
    //编辑联络内容
    async saveContact() {
      const res = await getEidtContactList(this.contactForm);
      if (res && res.code == 200) {
        this.dialogId = 0;
        this.getContactList();
      }
    },
    //取消课程
    async getCancelClass() {
      const res = await getCancleClassById({
        orderId: this.classItemDetail.classOrderId,
        orderRemark: this.cancelReason,
      });
      if (res && res.code == 200) {
        this.dialogId = null;
        this.getClassOrderList();
      }
    },
    goHome() {
      this.$router.push("/index");
    },
    //上課錄影上傳文件
    // handleRemove(file, fileList) {
    // },
    //分页列表
    handleSizeChange(val) {
      if (this.stuId == 0) {
        this.searchContactData.pageNum = val;
        this.getContactList();
      } else if (this.stuId == 1) {
        this.searchLeasonData.pageNum = val;
        this.getClassOrderList();
      } else if (this.stuId == 3) {
        this.searchTestScoreData.pageNum = val;
        this.getTestScoreList();
      } else {
        this.searchLearnData.pageNum = val;
        this.getLearnPlanList();
      }
    },
    handleCurrentChange(val) {
      console.log(this.stuId);
      if (this.stuId == 0) {
        this.searchContactData.pageNum = val;
        this.getContactList();
      } else if (this.stuId == 1) {
        this.searchLeasonData.pageNum = val;
        this.getClassOrderList();
      } else if (this.stuId == 3) {
        this.searchTestScoreData.pageNum = val;
        this.getTestScoreList();
      } else {
        this.searchLearnData.pageNum = val;
        this.getLearnPlanList();
      }
    },
    //彈窗流程
    openStuDialog(id, item) {
      this.dialogId = id;
      if (id == 3) {
        this.readForm = item;
      } else if (id == 6) {
        const { academicRecord } = item;
        this.academicForm.academicRecord = academicRecord
          ? academicRecord
          : null;
      } else if (id == 4) {
        this.contactForm = item;
      } else if (id == 9) {
        this.testScoreForm = item;
      } else {
        if (item) {
          this.classItemDetail = item;
        }
      }
    },
    //上课状态中文
    rendeStatusString(id) {
      let strL = ["預約中", "準備中", "上課中", "已下課", "已取消", "已完成"];
      return strL[id - 1];
    },
  },
};
</script>
<style>
@import "../../assets/css/manager_center.css";
.count_6 {
  display: flex;
}
.grey {
  background-color: #bfbfbf;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 5px;
}
.dis_flex {
  display: flex;
}
.dis_flex > div {
  width: 50%;
}
.manager_record_search div {
  width: 100%;
  height: 36px;
}
.paginations {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pup_manager_input_container .el-upload {
  width: 100%;
}
.el-table thead.is-group th {
  background: #000;
}
.el-table tbody .cell {
  color: #000;
  font-size: 16px;
  text-align: center;
}
.el-table thead th {
  padding: 0;
  background: #000;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
.el-input--prefix .el-input__inner {
  border: 1px solid #9e9e9e !important;
  color: #757575;
}
.el-input__inner::-webkit-input-placeholder {
  color: #757575;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>